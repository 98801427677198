import {
  Button,
  Card,
  CardActions,
  CardContent,
  useTheme,
} from "@mui/material";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import React from "react";
import ServiceTable from "./ServiceTable";

const ServiceCard = () => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        maxWidth: "500px",
        backgroundColor: (theme) => theme.palette.transparent.white1,
      }}
      square
    >
      <CardContent>
        <ServiceTable />
      </CardContent>
      <CardActions style={{ justifyContent: "center" }} disableSpacing>
        <Button aria-label="show more" color="primary" variant="outlined">
          <AnchorLink
            to="/services"
            gatsbyLinkProps={{
              style: {
                textDecoration: "none",
                fontWeight: "600",
                color: theme.palette.primary.main,
                minWidth: "100%",
              },
            }}
          >
            More info...
          </AnchorLink>
        </Button>
      </CardActions>
    </Card>
  );
};

export default ServiceCard;
