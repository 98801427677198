import {
  Card,
  CardActionArea,
  CardHeader,
  CardMedia,
  Typography,
} from "@mui/material";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import React from "react";
import { purples } from "../theme";

const BlogCard = ({ blog }) => {
  return (
    <AnchorLink
      to={`${blog.slug}#`}
      gatsbyLinkProps={{
        style: {
          textDecoration: "none",
        },
      }}
    >
      <Card sx={{ maxWidth: "500px" }} square>
        <CardActionArea>
          <CardHeader
            sx={{
              backgroundColor: purples[2],
              color: "white",
            }}
            title={
              <Typography variant="h4" align="center">
                {blog.category.toUpperCase()}
              </Typography>
            }
          />
          <CardMedia
            sx={{
              height: 0,
              paddingTop: "100%", // 16:9
            }}
            image={blog.tile}
            title={blog.shortTitle}
          />
        </CardActionArea>
      </Card>
    </AnchorLink>
  );
};

export default BlogCard;
