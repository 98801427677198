import { Box, Button, Grid, Typography } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import {
  composeValidators,
  email,
  phoneNumber,
  required,
} from "./form/validators";
import WrappedRadioGroup from "./form/wrapper/WrappedRadioGroup";
import WrappedTextField from "./form/wrapper/WrappedTextField";

const ContactForm = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          endpoints {
            contactEndpoint
          }
        }
      }
    }
  `);

  const [submitResult, setSubmitResult] = useState(null);
  const [sending, setSending] = useState(false);

  const onSubmit = (values) => {
    setSending(true);
    return fetch(data.site.siteMetadata.endpoints.contactEndpoint, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((result) =>
        setSubmitResult({
          success: true,
          result,
        })
      )
      .catch((result) =>
        setSubmitResult({
          success: false,
          result,
        })
      )
      .finally(() => setSending(false));
  };

  if (submitResult) {
    if (submitResult.success) {
      return (
        <Box
          sx={{
            padding: (theme) => theme.spacing(2),
            backgroundColor: (theme) => theme.palette.transparent.white1,
          }}
        >
          <Typography variant="body1">
            Successfully received contact request
          </Typography>
          <Button variant="contained" onClick={() => setSubmitResult(null)}>
            Submit again
          </Button>
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            padding: (theme) => theme.spacing(2),
            backgroundColor: (theme) => theme.palette.transparent.white1,
          }}
        >
          <Typography variant="body1">
            A problem occured submitting you request. Please try again.
          </Typography>
          <Button variant="contained" onClick={() => setSubmitResult(null)}>
            Submit again
          </Button>
        </Box>
      );
    }
  }

  const validateMessage = (value, otherValues) => {
    return otherValues.subject === "Other" && !value ? "Required" : undefined;
  };

  return (
    <Box
      sx={{
        padding: (theme) => theme.spacing(2),
        backgroundColor: (theme) => theme.palette.transparent.white1,
      }}
    >
      <Form
        onSubmit={onSubmit}
        render={({ form, pristine, handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit} autoComplete="off" noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Field
                  name="name"
                  component={WrappedTextField}
                  label="Your name"
                  variant="outlined"
                  fullWidth
                  required
                  validate={required}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="phoneNumber"
                  component={WrappedTextField}
                  label="Your contact number"
                  variant="outlined"
                  fullWidth
                  required
                  validate={composeValidators(required, phoneNumber)}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="email"
                  component={WrappedTextField}
                  label="Your email address"
                  variant="outlined"
                  fullWidth
                  required
                  validate={composeValidators(required, email)}
                />
              </Grid>
              <Grid item xs={12} sm={7} lg={5}>
                <Field
                  name="subject"
                  component={WrappedRadioGroup}
                  label="I'm interested in"
                  variant="outlined"
                  options={[
                    {
                      value: "More info",
                      label: "More info",
                    },
                    {
                      value: "Book a physio consultation",
                      label: "Book a physio consultation",
                    },
                    {
                      value: "Book a performance consultation",
                      label: "Book a performance consultation",
                    },
                    {
                      value: "Other",
                      label: "Other",
                    },
                  ]}
                  fullWidth
                  required
                  validate={required}
                />
              </Grid>
              <Grid item xs={12} sm={5} lg={7}>
                <Field
                  name="message"
                  component={WrappedTextField}
                  label="Message"
                  variant="outlined"
                  multiline
                  minRows={3}
                  maxRows={10}
                  fullWidth
                  required={values.subject === "Other"}
                  validate={validateMessage}
                />
              </Grid>
              <Grid item xs={12} container spacing={1} justifyContent="center">
                <Grid item>
                  <Button
                    variant="outlined"
                    type="submit"
                    disabled={submitting || sending}
                  >
                    Send
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled={submitting || pristine}
                    onClick={form.reset}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Box>
  );
};

export default ContactForm;
