export const required = (value) => (value ? undefined : 'Required');

export const mustBeNumber = (value) =>
  isNaN(value) ? 'Must be a number' : undefined;

export const minValue = (min) => (value) =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;

export const email = (value) => {
  const regex = /^\w+([.-]?\+?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return !value || regex.test(value) ? undefined : 'Invalid email address';
};

export const phoneNumber = (value) => {
  const regex = /^[0-9]{10}$/;
  return !value || regex.test(value)
    ? undefined
    : 'Invalid phone number. Include area code and no spaces';
};

export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);
