import { Grid, Typography } from "@mui/material";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const TeamMemberCard = ({ to, name, image, title }) => {
  return (
    <AnchorLink
      to={to}
      gatsbyLinkProps={{
        style: {
          textDecoration: "none",
          color: "inherit",
        },
      }}
    >
      <Grid container direction="column">
        <Grid item>
          <GatsbyImage image={image} alt={"name"} />
        </Grid>
        <Grid item>
          <Typography variant="body1" align="center">
            {name} - {title}
          </Typography>
        </Grid>
      </Grid>
    </AnchorLink>
  );
};

export default TeamMemberCard;
