import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import TrainIcon from "@mui/icons-material/Train";
import TramIcon from "@mui/icons-material/Tram";
import { Grid, Typography } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { getImage } from "gatsby-plugin-image";
import * as React from "react";
import { Parallax } from "react-parallax";
import BackgroundSection from "../components/BackgroundSection";
import BlogCard from "../components/BlogCard";
import BookNowButton from "../components/BookNowButton";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import PageSection from "../components/PageSection";
import PrepBox from "../components/PrepBox";
import ServiceCard from "../components/ServiceCard";
import StyledMap from "../components/StyledMap";
import TeamMemberCard from "../components/TeamMemberCard";
import TopBanner from "../components/TopBanner";
import TopBar from "../components/TopBar";
import instaPosts from "../content/insta-posts.json";
import { greys, purples } from "../theme";

const IndexPage = () => {
  const {
    mainBackground,
    serviceBackground,
    meaganPortrait,
    rafPortrait,
    contactBackground,
    site,
    allMarkdownRemark,
  } = useStaticQuery(
    graphql`
      query {
        mainBackground: file(relativePath: { eq: "rowing-ant.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1024
              placeholder: BLURRED
              formats: [AUTO, JPG]
            )
          }
        }
        serviceBackground: file(relativePath: { eq: "overhead-weights.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 5192
              placeholder: BLURRED
              formats: [AUTO, JPG]
            )
          }
        }
        meaganPortrait: file(relativePath: { eq: "meagan2.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 818
              placeholder: BLURRED
              formats: [AUTO, JPG]
            )
          }
        }
        rafPortrait: file(relativePath: { eq: "raf.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 818
              placeholder: BLURRED
              formats: [AUTO, JPG]
            )
          }
        }
        contactBackground: file(
          relativePath: { eq: "one-handed-handstand.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              width: 5192
              placeholder: BLURRED
              formats: [AUTO, JPG]
            )
          }
        }
        site {
          siteMetadata {
            contact {
              email
              phoneNumber
            }
          }
        }
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          limit: 1000
        ) {
          edges {
            node {
              frontmatter {
                slug
                shortTitle
                category
                digest
                tile
              }
            }
          }
        }
      }
    `
  );

  const mainBackgroundImage = getImage(mainBackground);
  const serviceBackgroundImage = getImage(serviceBackground);
  const meaganPortraitImage = getImage(meaganPortrait);
  const rafPortraitImage = getImage(rafPortrait);
  const contactBackgroundImage = getImage(contactBackground);
  console.log("serviceBackgroundImage", serviceBackgroundImage);

  const getPhoneNumber = () => site.siteMetadata.contact.phoneNumber;
  const getEmail = () => site.siteMetadata.contact.email;

  const paragraphStyle = {
    color: "black",
    marginBottom: "1em",
    "&:last-of-type": {
      marginBottom: 0,
    },
  };

  const wrapIconStyle = {
    alignItems: "center",
    display: "flex",
  };
  const iconStyle = {
    marginRight: (theme) => theme.spacing(1),
  };

  return (
    <>
      <TopBar />
      <BackgroundSection
        backgroundPosition="center center"
        backgroundImage={mainBackgroundImage}
      >
        <TopBanner
          heading="PHYSIO + PERFORMANCE"
          texts={["Prevent injuries.", "Move well.", "Perform better."]}
        />
      </BackgroundSection>
      <PageSection
        id="howWeCanHelp"
        title="How we can help you"
        bgColor={greys[0]}
        labelColor="black"
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item sm={12} md={6}>
            <PrepBox />
          </Grid>
          <Grid item sm={12} md={6}>
            <Typography
              variant="body1"
              sx={paragraphStyle}
              align="justify"
              gutterBottom
            >
              PREP Physio + Performance provides a high-quality service in an
              elite space, with the knowledge to match. We will provide a 1:1
              client facing service, which will consist of movement pattern
              retraining and education as the primary treatment strategies. Our
              goal is to help you move well, so you can return to your
              activities.
            </Typography>
            <Typography
              variant="body1"
              sx={paragraphStyle}
              align="justify"
              gutterBottom
            >
              PREP Physio + Performance will use the initial consultation to
              provide a thorough assessment and prescribe appropriate and
              progressive exercises. We will also use education as a tool, to
              ensure you have a full understanding as to the management pathway,
              in order to achieve your goals.
            </Typography>
          </Grid>
        </Grid>
      </PageSection>
      <Parallax
        blur={0}
        bgImage={serviceBackgroundImage.images.fallback.src}
        bgImageAlt="Services"
        strength={500}
      >
        <div id="services" style={{ height: "20px" }}></div>
        <PageSection id="services-inner" title="Services" labelColor="white">
          <div style={{ height: "20px" }}></div>
          <Grid container justifyContent="center">
            <Grid item>
              <ServiceCard />
            </Grid>
          </Grid>
          <div style={{ height: "40px" }}></div>
        </PageSection>
      </Parallax>
      <PageSection
        id="theTeam"
        title="The Team"
        bgColor={purples[3]}
        labelColor="black"
      >
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <TeamMemberCard
              to="/team#meagan"
              name="Meagan"
              title="Director + Physio"
              image={meaganPortraitImage}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TeamMemberCard
              to="/team#raf"
              name="Raf"
              title="Exercise Scientist"
              image={rafPortraitImage}
            />
          </Grid>
        </Grid>
      </PageSection>

      <PageSection
        id="blog"
        title="Blog"
        labelColor={purples[4]}
        bgColor={greys[0]}
      >
        <div style={{ height: "20px" }}></div>
        <Grid container>
          {allMarkdownRemark.edges.map(({ node: { frontmatter } }) => (
            <Grid key={frontmatter.slug} item xs={12} sm={6} md={4}>
              <BlogCard blog={frontmatter} />
            </Grid>
          ))}
        </Grid>
        <div style={{ height: "40px" }}></div>
      </PageSection>

      <Parallax
        blur={0}
        bgImage={contactBackgroundImage.images.fallback.src}
        bgImageAlt="Contact"
        strength={500}
      >
        <div id="contact" style={{ height: "20px" }}></div>
        <PageSection id="contact-inner" title="Get in touch" labelColor="white">
          <Typography
            variant="body1"
            style={{ color: "white" }}
            align="center"
            gutterBottom
          >
            We'd love to hear from you via our contact form below.
            <br />
            Alternatively you can email us at{" "}
            <a
              style={{ color: "white" }}
              href={`mailto:${getEmail()}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getEmail()}
            </a>{" "}
            or call us on{" "}
            <a
              style={{ color: "white" }}
              href={`tel:${getPhoneNumber()}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getPhoneNumber()}
            </a>
            . Ready to book? If so, feel free to complete a{" "}
            <AnchorLink
              gatsbyLinkProps={{
                style: {
                  color: "white",
                },
              }}
              to="/book#booking"
            >
              booking request
            </AnchorLink>
            .
          </Typography>
          <div>
            <ContactForm />
          </div>
          <div style={{ height: "40px" }}></div>
        </PageSection>
      </Parallax>
      <PageSection
        id="location"
        title="Where we are"
        bgColor={purples[3]}
        labelColor="black"
      >
        <div
          style={{
            height: "50vh",
            minWidth: "100%",
          }}
        >
          <StyledMap />
        </div>
        <br />
        <Typography
          variant="h4"
          sx={paragraphStyle}
          align="center"
          gutterBottom
        >
          Getting here
        </Typography>
        <Grid container spacing={0}>
          <Grid item>
            <Typography variant="body1" sx={wrapIconStyle}>
              <DirectionsCarIcon sx={iconStyle} />1 hour parking is available
              directly out the front of the clinic on Burwood Road.
              Alternatively, 2 hour parking is available directly across the
              road at the IGA.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" sx={wrapIconStyle}>
              <TrainIcon sx={iconStyle} />
              Hawthorn Train Station is a 1 minute walk from the clinic.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" sx={wrapIconStyle}>
              <TramIcon sx={iconStyle} />
              The 75 tram goes directly past the clinic on Burwood road.
            </Typography>
          </Grid>
        </Grid>
      </PageSection>
      <PageSection
        id="feed"
        title="What we're up to"
        bgColor={greys[0]}
        labelColor="black"
      >
        <Grid container spacing={1}>
          {instaPosts.posts.map((p, i) => (
            <Grid key={i} item dangerouslySetInnerHTML={{ __html: p }} />
          ))}
        </Grid>
        {/* <InstagramFeed /> */}
      </PageSection>
      <BookNowButton />
      <Footer />
    </>
  );
};

export default IndexPage;
