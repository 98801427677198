import { graphql, useStaticQuery } from "gatsby";
import GoogleMapReact from "google-map-react";
import React from "react";
import Logo from "../images/PREP_Logo_Insta_FB.png";

const shopLocation = {
  address: "1/93 Burwood Rd, Hawthorn",
  lat: -37.821322,
  lng: 145.025836,
  markerSize: 40,
};

const Marker = () => {
  return (
    <div
      style={{
        marginLeft: `-${shopLocation.markerSize / 2}px`,
        marginTop: `-${shopLocation.markerSize / 2}px`,
      }}
    >
      <img
        style={{
          padding: "2px",
        }}
        src={Logo}
        width={`${shopLocation.markerSize}px`}
        height={`${shopLocation.markerSize}px`}
        alt="logo"
        title={shopLocation.address}
      />
    </div>
  );
};

const StyledMap = () => {
  const data = useStaticQuery(graphql`
    query ApiQuery {
      site {
        siteMetadata {
          google {
            mapsApiKey
          }
        }
      }
    }
  `);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: data.site.siteMetadata.google.mapsApiKey }}
      defaultCenter={{
        lat: shopLocation.lat,
        lng: shopLocation.lng,
      }}
      defaultZoom={16}
    >
      <Marker lat={shopLocation.lat} lng={shopLocation.lng} />
    </GoogleMapReact>
  );
};

export default StyledMap;
