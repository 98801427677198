import { Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";

const PrepBox = () => {
  const smallWidth = useMediaQuery("(max-device-width: 400px)");

  const prepBoxLeft = {
    maxWidth: "180px",
    minWidth: "180px",
    minHeight: "180px",
    maxHeight: "180px",
    marginLeft: "auto",
  };
  const prepBoxRight = {
    maxWidth: "180px",
    minWidth: "180px",
    minHeight: "180px",
    maxHeight: "180px",
  };
  const prepBoxLeftXs = {
    maxWidth: "160px",
    minWidth: "160px",
    minHeight: "160px",
    maxHeight: "160px",
    marginLeft: "auto",
  };
  const prepBoxRightXs = {
    maxWidth: "160px",
    minWidth: "160px",
    minHeight: "160px",
    maxHeight: "160px",
  };

  //  TODO fix with css 'aspect-ratio: attr(width) / attr(height);'

  return (
    <>
      <Grid container spacing={0}>
        <Grid xs={6} item>
          <Grid
            sx={{
              color: "white",
              backgroundColor: "#8d42f6",
              width: "100%",
              height: "20px",
              paddingTop: "calc(50% - 10px)",
              paddingBottom: "calc(50% - 10px)",
            }}
            container
            justifyContent="center"
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="subtitle2" align="center">
                PREPARATION
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} item>
          <Grid
            sx={{
              color: "#8d42f6",
              backgroundColor: "white",
              width: "100%",
              height: "20px",
              paddingTop: "calc(50% - 10px)",
              paddingBottom: "calc(50% - 10px)",
            }}
            container
            justifyContent="center"
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="subtitle2" align="center">
                REHABILITATION
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} item>
          <Grid
            sx={{
              color: "black",
              backgroundColor: "white",
              width: "100%",
              height: "20px",
              paddingTop: "calc(50% - 10px)",
              paddingBottom: "calc(50% - 10px)",
            }}
            container
            justifyContent="center"
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="subtitle2" align="center">
                EXERCISE
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} item>
          <Grid
            sx={{
              color: "white",
              backgroundColor: "black",
              width: "100%",
              height: "20px",
              paddingTop: "calc(50% - 10px)",
              paddingBottom: "calc(50% - 10px)",
            }}
            container
            justifyContent="center"
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="subtitle2" align="center">
                PROGRAMMING
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PrepBox;
